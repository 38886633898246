<template>
  <div class="sales">
    <v-card>
      <v-card-title>Laporan Stock</v-card-title>

      <v-divider></v-divider>

      <v-list>
        <v-list-item-group color="light-blue">
          <template v-for="report in reports">
            <v-list-item :key="report.id" :to="'/inventory' + report.link">
              <v-list-item-icon>
                <v-icon v-text="report.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-text="report.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider
              v-if="report.divider"
              :key="report.id"
              class="mx-4"
            ></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Inventory",

  data: () => ({
    reports: [
      {
        icon: "mdi-package-variant",
        title: "Master Item",
        link: "/items",
        divider: true,
      },
      {
        icon: "mdi-package-variant",
        title: "Penjualan Item",
        link: "/items/sales",
      },
    ],
  }),
};
</script>